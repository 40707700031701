import {
  AddIcon,
  ArrowForwardIcon,
  EditIcon,
  SmallAddIcon,
} from '@chakra-ui/icons'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Skeleton,
  Spinner,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CleanOutlineButton } from '../../modules/user/buttons/CleanOutlineButton'
import { Title } from '../../modules/user/labels/Title'
import { SubTitle } from '../../modules/user/labels/Subtitle'
import { QRPubkeyScan } from '../../modules/puffPay/QRPubkeyScan'
import { BackIcon } from '../../modules/puffPay/BackIcon'
import { ConsumerNavbar } from '../../modules/user/Navbar'
import { useRouter } from 'next/router'
import { usePayleafUser } from '../../modules/user/consumerHooks'
import { trpc } from '../../utils/trpc'
import { toast } from 'react-hot-toast'
import { useUser } from '../../modules/common/auth/authHooks'
import { UserPaymentInfoListItem } from '../../modules/user/components/PaymentInfoListItem'
import { useSocialWallet } from '../../modules/common/hooks/useSocialWallet'
import _ from 'lodash'
import { StripeOnRamp } from '../../modules/onramp/StripeOnRamp'
import { TopUpAccount } from '../../modules/user/screens/topup/topUpAccount'
import { GetServerSideProps } from 'next'
import axios from 'axios'
import { getClientIp } from 'request-ip'
import RecentTransactionScreen from '../../modules/user/screens/RecentTransactions'
import { useBalance } from '../../modules/user/hooks/useBalance'
import CoinListViewScreen from '../../modules/user/screens/CoinListViewScreen'
import NumPad from '../../modules/common/components/numPad'
import { BiChevronDown } from 'react-icons/bi'
import { consumerPrimaryButtonStyleFull } from '../../modules/user/buttonStyle'
import { EToken } from '@prisma/client'
import { FiEdit3 } from 'react-icons/fi'
import { IoCheckmarkSharp, IoCloseSharp } from 'react-icons/io5'
import CustomPayLeafTab from '../../modules/user/components/CustomTab'
import { BsBank, BsLink45Deg } from 'react-icons/bs'
import ButtonListItem from '../../modules/common/components/buttonListItem'
import { PublicKey } from '@solana/web3.js'

enum EFundsOptionsToShow {
  HOME,
  ADD_MONEY,
  SEND_MONEY,
  QR_CODE,
  STRIPE,
}

enum EAppListOptionsToShow {
  TRANSACTIONS,
  COINS,
  COLLECTIBLES,
}

enum ESendFundOptionToShow {
  AMOUNT,
  DESTINATION,
  CONFIRM,
}

/*export const getServerSideProps: GetServerSideProps<{ ip?: string }> = async (ctx) => {

  console.log('detected', getClientIp(ctx.req))

  console.log('remoteAddress', ctx.req.socket.remoteAddress);
  
  const forwarded = ctx.req.headers['x-forwarded-for']
  console.log({forwarded});
  
  const ip = typeof forwarded === 'string' ? forwarded.split(/, /)[0] : ctx.req.socket.remoteAddress
  console.log('serverside ip', ip)

  return {
    props: {
      ip,
    },
  }
}*/

const formInputStyle: React.CSSProperties = {
  border: 'none',
  borderRadius: '12px',
  fontSize: '.875rem',
  fontWeight: 500,
  background: '#f1f4f5',
  color: '#333',
  transition: 'all .2s ease-in-out',
}

export default function PayLeafConsumerApp() {
  const [fundsOptionToShow, setFundOptionToShow] =
    useState<EFundsOptionsToShow>(EFundsOptionsToShow.HOME)

  const [appListViewToShow, setAppListViewToShow] =
    useState<EAppListOptionsToShow>(EAppListOptionsToShow.COINS)

  const [sendFundsOptionToShow, setSendFundsOptionToShow] =
    useState<ESendFundOptionToShow>(ESendFundOptionToShow.AMOUNT)

  const router = useRouter()

  const {
    inited,
    web3auth,
    isLoggedIn,
    initedAndNotLoggedIn,
    userInfoRes,
    pubKey,
    balance,
    loginWithGoogle,
  } = useSocialWallet()
  const { isAuthed, solanaAuth } = useUser()

  const payleafUserRes = usePayleafUser()
  const registerPayLeafUserMut = trpc.useMutation(
    'user.registerPayLeafConsumer'
  )

  useEffect(() => {
    if (
      payleafUserRes.isFetched &&
      isAuthed &&
      inited &&
      isLoggedIn &&
      !!userInfoRes.data?.user?.email &&
      solanaAuth?.wallet?.toBase58() === pubKey &&
      !!solanaAuth?.tx
    ) {
      console.log('useeffect refetch triggered')

      payleafUserRes.refetch().then(async (res) => {
        console.log('data from payleaf user res', res.data)
        if (!res.data) {
          const user = await registerPayLeafUserMut.mutateAsync({
            email: userInfoRes.data?.user.email!,
            name: userInfoRes.data?.user.name,
            verifier: userInfoRes.data?.user.verifier,
            aggregateVerifier: userInfoRes.data?.user.aggregateVerifier,
          })
          console.log('new user created', user.email)
        }
      })
    }
  }, [payleafUserRes.isFetched, isAuthed, userInfoRes])

  useEffect(() => {
    console.log(isLoggedIn, inited, userInfoRes)

    if (initedAndNotLoggedIn) {
      router.push('/app/signup')
    }
  }, [
    isAuthed,
    inited,
    web3auth,
    isLoggedIn,
    userInfoRes,
    initedAndNotLoggedIn,
  ])

  const {
    data: balanceData,
    isLoading: isTokenBalanceLoading,
    total: totalBalance,
  } = useBalance()

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModelClose,
  } = useDisclosure()
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure()
  const [numPadValue, setNumPadValue] = useState<number | undefined>(undefined)
  const handleChange = (value: any) => {
    console.log(typeof value, value)

    value = value.replace(',', '.')

    if (value && (value.match(/\./g) ?? 0).length > 1) {
      return
    }

    setNumPadValue(
      !!value
        ? value.includes('.')
          ? value
          : value > 0
          ? Number(value)
          : 0
        : value
    )
  }

  const sendMoneyNumberInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (fundsOptionToShow === EFundsOptionsToShow.SEND_MONEY) {
      sendMoneyNumberInputRef.current?.focus()
    }
  }, [fundsOptionToShow])

  const [selectedCoin, setSelectedCoin] = useState<string>(EToken.USDC)
  const balanceInSelectedCoin = useMemo(() => {
    if (selectedCoin === EToken.USDC) {
      return balanceData?.usdc.usd
    } else if (selectedCoin === EToken.SOL) {
      return balanceData?.sol.usd
    } else if (selectedCoin === EToken.PUFF) {
      return balanceData?.puff.usd
    } else {
      return 0
    }
  }, [selectedCoin, balanceData])

  const isValidSendMoneyValue = useMemo(() => {
    return (
      (numPadValue ?? 0) > 0 &&
      (numPadValue ?? 0) < (balanceInSelectedCoin ?? 0)
    )
  }, [numPadValue, balanceInSelectedCoin])
  console.log({ isValidSendMoneyValue })

  const [sendMoneyDestination, setSendMoneyDestinationInput] =
    useState<string>('')

  const isSendMoneyDestinationAValidSolanaAddress = useMemo(() => {
    if (sendMoneyDestination.length >= 30) {
      return PublicKey.isOnCurve(sendMoneyDestination)
    }
  }, [sendMoneyDestination])

  const [showUsernameEditIcon, setShowUsernameEditIcon] =
    useState<boolean>(false)
  const [isEditUsernameActivated, setEditUsernameActivated] =
    useState<boolean>(false)

  const [usernameToEdit, setUsernameToEdit] = useState<string>('')

  const editPayLeafUserRes = trpc.useMutation('user.editPayLeafUser', {
    onSuccess(data) {
      payleafUserRes.refetch()
    },
  })

  return (
    <Box fontFamily={'Inter'} bg='#F6F9F5' minH='100vh' paddingBottom='2rem'>
      <Flex
        bg='#F9F9F9'
        justifyContent={'center'}
        borderBottomRadius={'24px'}
        paddingBottom='3rem'
      >
        <Box width='100%' maxWidth='440px' paddingY='1rem' paddingX='1rem'>
          <ConsumerNavbar name={userInfoRes.data?.user.name}></ConsumerNavbar>

          {!pubKey && (
            <Flex mt='2rem' justifyContent={'center'} alignItems='center'>
              <Text
                textAlign={'center'}
                color='#333'
                fontSize='1.4rem'
                fontWeight={600}
              >
                Loading your profile...
              </Text>
              <Spinner ml={2} size='sm'></Spinner>
            </Flex>
          )}

          {!!pubKey && (
            <Text
              mt='2rem'
              textAlign={'center'}
              color='#333'
              fontSize='1.4rem'
              fontWeight={600}
            >
              Welcome back,{' '}
              <Text
                textDecoration={'underline'}
                textDecorationColor={'#65B28A'}
                color='#333'
                fontSize='1.4rem'
                fontWeight={600}
                as='span'
              >
                {userInfoRes.data?.user.name}
              </Text>
            </Text>
          )}

          {payleafUserRes.payLeafUser?.username && (
            <Flex
              justifyContent={'center'}
              alignItems='center'
              onMouseEnter={() => {
                setShowUsernameEditIcon(true)
              }}
              onMouseLeave={() => {
                setShowUsernameEditIcon(false)
              }}
              _hover={{ cursor: 'pointer' }}
            >
              {payleafUserRes.isLoading || editPayLeafUserRes.isLoading ? (
                <Spinner color='black'></Spinner>
              ) : (
                <>
                  {!isEditUsernameActivated && (
                    <Box
                      display='inline-block'
                      onClick={() => {
                        setEditUsernameActivated(true)
                        setUsernameToEdit(
                          payleafUserRes.payLeafUser?.username ?? ''
                        )
                      }}
                    >
                      <Text
                        fontSize={'1.1rem'}
                        fontWeight={600}
                        color='#979a9c'
                        textAlign={'center'}
                      >
                        @{payleafUserRes.payLeafUser?.username}
                        {showUsernameEditIcon && (
                          <Box display='inline' ml={2}>
                            <FiEdit3
                              fontSize={'1.1rem'}
                              color='#979a9c'
                            ></FiEdit3>
                          </Box>
                        )}
                      </Text>
                    </Box>
                  )}

                  {isEditUsernameActivated && (
                    <>
                      <Input
                        minWidth='3rem'
                        width='fit-content'
                        textAlign={'center'}
                        fontSize={'1.1rem'}
                        fontWeight={600}
                        color='#979a9c'
                        type='text'
                        variant='unstyled'
                        value={`@${usernameToEdit}`}
                        onChange={(e) => {
                          setUsernameToEdit(e.target.value.substring(1))
                        }}
                      ></Input>
                      <IoCheckmarkSharp
                        onClick={async () => {
                          editPayLeafUserRes.mutate({
                            username: usernameToEdit,
                          })
                          setEditUsernameActivated(false)
                        }}
                        fontSize={'1.4rem'}
                        fontWeight={600}
                        color='black'
                      ></IoCheckmarkSharp>
                      <Box display='inline' ml={2}>
                        <IoCloseSharp
                          onClick={() => {
                            setEditUsernameActivated(false)
                          }}
                          fontSize={'1.4rem'}
                          fontWeight={600}
                          color='black'
                        ></IoCloseSharp>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Flex>
          )}

          {isTokenBalanceLoading && (
            <Flex mt='1rem' justifyContent='center'>
              <Skeleton width='8rem' height='2.6rem' borderRadius='10px' />
            </Flex>
          )}

          {/*<SubTitle fontSize={'.75rem'} mt='1.4rem' textAlign={'center'}>Your balance</SubTitle>*/}
          {!_.isNil(totalBalance) && !isTokenBalanceLoading && (
            <Text
              mt='1.4rem'
              textAlign={'center'}
              fontSize='2.8rem'
              fontWeight={800}
              color='#333333'
              lineHeight={'90%'}
              _before={{
                content: `"$"`,
                fontSize: '1.4rem',
                fontWeight: 500,
                verticalAlign: 'super',
                mr: '.1rem',
              }}
              _after={{
                content: `".${totalBalance?.toFixed(2).split('.')[1]}"`,
                fontSize: '1.4rem',
                fontWeight: 500,
                verticalAlign: 'super',
                ml: '.1rem',
              }}
            >
              {Math.floor(totalBalance)}
            </Text>
          )}

          {fundsOptionToShow === EFundsOptionsToShow.HOME && (
            <>
              <HStack mt='4rem' justifyContent={'center'} spacing={3} mb='1rem'>
                <CleanOutlineButton
                  height='44px'
                  bg='#fff'
                  onClick={() =>
                    setFundOptionToShow(EFundsOptionsToShow.ADD_MONEY)
                  }
                  color='#333'
                >
                  <Flex alignItems={'center'}>
                    <Flex
                      width='20px'
                      height='20px'
                      alignItems={'center'}
                      justifyContent='center'
                      bg='#333'
                      borderRadius='full'
                      mr='.4rem'
                    >
                      <SmallAddIcon w={5} h={5} color='white'></SmallAddIcon>
                    </Flex>
                    <Box height='18px'>ADD FUNDS</Box>
                  </Flex>
                </CleanOutlineButton>
                <CleanOutlineButton
                  bg='#fff'
                  height='44px'
                  color='#333'
                  onClick={() =>
                    setFundOptionToShow(EFundsOptionsToShow.SEND_MONEY)
                  }
                >
                  <Flex alignItems={'center'}>
                    <Box height='18px'>SEND FUNDS </Box>
                    <Flex
                      width='20px'
                      height='20px'
                      alignItems={'center'}
                      justifyContent='center'
                      bg='#333'
                      borderRadius='full'
                      ml='.4rem'
                    >
                      <ArrowForwardIcon
                        color='white'
                        w={4}
                        h={4}
                        borderRadius='full'
                      ></ArrowForwardIcon>
                    </Flex>
                  </Flex>
                </CleanOutlineButton>
              </HStack>
              <Flex justifyContent={'center'}>
                <CleanOutlineButton height='44px' color='#333' bg='#fff'>
                  Invite friends 🎉
                  <Text fontSize={'.8rem'} ml='.3rem' as='span' color='#787878'>
                    (coming soon)
                  </Text>
                </CleanOutlineButton>
              </Flex>
            </>
          )}

          {fundsOptionToShow === EFundsOptionsToShow.ADD_MONEY && (
            <TopUpAccount
              onBack={() => setFundOptionToShow(EFundsOptionsToShow.HOME)}
            ></TopUpAccount>
          )}
          {fundsOptionToShow === EFundsOptionsToShow.SEND_MONEY && (
            <Box mt='2rem'>
              <BackIcon
                aria-label='back-icon'
                onBack={() => {
                  if (sendFundsOptionToShow === ESendFundOptionToShow.CONFIRM) {
                    setSendFundsOptionToShow(ESendFundOptionToShow.DESTINATION)
                  } else if (
                    sendFundsOptionToShow === ESendFundOptionToShow.DESTINATION
                  ) {
                    setSendFundsOptionToShow(ESendFundOptionToShow.AMOUNT)
                  } else {
                    setFundOptionToShow(EFundsOptionsToShow.HOME)
                  }
                }}
              ></BackIcon>
              <Title textDecoration='underline' textDecorationColor={'#65B28A'}>
                Send funds
              </Title>
              {sendFundsOptionToShow === ESendFundOptionToShow.AMOUNT && (
                <Box>
                  <Flex mt='3rem' justifyContent={'center'}>
                    <Button
                      onClick={() => {
                        if (balanceInSelectedCoin)
                          setNumPadValue(
                            Math.floor(balanceInSelectedCoin * 100) / 100
                          )
                      }}
                      bg='#f6fafc'
                      color='#76767b'
                      border='none'
                      size='sm'
                    >
                      MAX
                    </Button>
                  </Flex>
                  <Flex mt='1rem' justifyContent={'center'}>
                    <NumberInput
                      color={numPadValue == 0 ? '#707f92' : '#1D1D20'}
                      value={'$' + (numPadValue ?? '0')}
                      onChange={handleChange}
                      variant='unstyled'
                    >
                      <NumberInputField
                        ref={sendMoneyNumberInputRef}
                        textAlign={'center'}
                        placeholder='0'
                        p={0}
                        fontSize='3.4rem'
                        fontWeight={600}
                        lineHeight={'90%'}
                      />
                    </NumberInput>
                  </Flex>
                  <Flex justifyContent={'center'}>
                    {(balanceInSelectedCoin ?? 0) < (numPadValue ?? 0) && (
                      <Badge borderRadius='10px' colorScheme={'red'}>
                        Insufficient balance in {selectedCoin}
                      </Badge>
                    )}
                  </Flex>
                  <Box
                    mt='3rem'
                    paddingY={3}
                    paddingX={4}
                    width='100%'
                    bg='#f1f4f5'
                    borderRadius={'12px'}
                  >
                    <Flex marginBottom='.6rem' justify={'space-between'}>
                      <Text
                        _hover={{ cursor: 'pointer' }}
                        fontWeight={600}
                        fontSize={'1rem'}
                        color='#1D1D20'
                        onClick={onDrawerOpen}
                        display='flex'
                        alignItems={'center'}
                      >
                        {selectedCoin}
                        <BiChevronDown fontSize={'1.2rem'}></BiChevronDown>
                      </Text>
                      <Text fontWeight={600} fontSize={'1rem'} color='#999'>
                        Pay in
                      </Text>
                    </Flex>
                    <Flex>
                      <Text
                        textAlign={'center'}
                        fontWeight={500}
                        fontSize='.825rem'
                        color='#999'
                      >
                        ${balanceInSelectedCoin?.toFixed(2)} in {selectedCoin}{' '}
                        available
                      </Text>
                    </Flex>
                  </Box>
                  <Button
                    style={consumerPrimaryButtonStyleFull}
                    mt='2rem'
                    onClick={() =>
                      setSendFundsOptionToShow(
                        ESendFundOptionToShow.DESTINATION
                      )
                    }
                    isDisabled={!isValidSendMoneyValue}
                  >
                    Next step
                  </Button>
                </Box>
              )}
              {sendFundsOptionToShow === ESendFundOptionToShow.DESTINATION && (
                <Box>
                  <FormControl>
                    <Box
                      paddingRight='1rem'
                      mt='1rem'
                      style={formInputStyle}
                      paddingTop='.4rem'
                      paddingBottom='.4rem'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      borderRadius={'12px'}
                      w='100%'
                    >
                      <Input
                        fontSize={'.875rem'}
                        fontWeight={500}
                        paddingY={0}
                        width='100%'
                        border='none'
                        placeholder='Type in a username, email or wallet address'
                        value={sendMoneyDestination}
                        onChange={(ev) => {
                          setSendMoneyDestinationInput(ev.target.value)
                        }}
                        type='text'
                      />
                      {false && <Spinner size='sm'></Spinner>}
                    </Box>
                  </FormControl>

                  <Stack
                    spacing='1.6rem'
                    padding='1rem'
                    bg='#fff'
                    mt='1rem'
                    borderRadius={'12px'}
                  >
                    <ButtonListItem
                      name='Send as a link'
                      description={
                        'Send money to anyone simply by sending them a link'
                      }
                      icon={
                        <BsLink45Deg
                          fontSize='1.6rem'
                          fill='green'
                        ></BsLink45Deg>
                      }
                    ></ButtonListItem>
                    <ButtonListItem
                      mt='1rem'
                      name='To your bank account (coming soon)'
                      description={
                        'Send money from Payleaf directly to your bank account. Coming soon.'
                      }
                      icon={<BsBank fontSize='1.6rem' fill='green'></BsBank>}
                    ></ButtonListItem>
                  </Stack>

                  <Box padding='1rem' bg='#fff' mt='1rem' borderRadius={'12px'}>
                    <Text fontSize='1rem' fontWeight={600} color='black'>
                      Previous destinations
                    </Text>
                    <Text color='#999' fontSize='.725rem'>
                      No previous destinations found
                    </Text>
                  </Box>

                  <Button
                    style={consumerPrimaryButtonStyleFull}
                    mt='2rem'
                    onClick={() =>
                      setSendFundsOptionToShow(ESendFundOptionToShow.CONFIRM)
                    }
                    isDisabled={!isValidSendMoneyValue}
                  >
                    Next step
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Flex>

      <Box paddingX='1rem'>
        <Tabs
          mt='1rem'
          onChange={(idx) => {
            if (idx === 0) {
              setAppListViewToShow(EAppListOptionsToShow.COINS)
            } else if (idx === 1) {
              setAppListViewToShow(EAppListOptionsToShow.TRANSACTIONS)
            } else if (idx === 2) {
              setAppListViewToShow(EAppListOptionsToShow.COLLECTIBLES)
            }
          }}
          position='relative'
          variant='unstyled'
        >
          <TabList>
            <CustomPayLeafTab>Coins</CustomPayLeafTab>
            <CustomPayLeafTab>Transactions</CustomPayLeafTab>
            <CustomPayLeafTab>Collectibles</CustomPayLeafTab>
          </TabList>
          {/*<TabIndicator
          mt='-4px'
          height='1px'
          bgGradient={
            'linear(to right, rgba(30,87,153,0) 0%, rgba(41,137,216,0) 25%,rgba(0,0,0,1) 26%, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 76%);'
          }
          paddingX='1rem'
          borderRadius='1px'
        />*/}
        </Tabs>

        {appListViewToShow === EAppListOptionsToShow.TRANSACTIONS && (
          <RecentTransactionScreen></RecentTransactionScreen>
        )}

        {appListViewToShow === EAppListOptionsToShow.COINS && (
          <Box height={'auto'}>
            <Title>Balance</Title>
            <CoinListViewScreen
              onCoinClick={(symbol) => {
                setSelectedCoin(symbol)
                setFundOptionToShow(EFundsOptionsToShow.SEND_MONEY)
              }}
            ></CoinListViewScreen>
          </Box>
        )}
      </Box>

      <Modal isOpen={isModalOpen} onClose={onModelClose} isCentered>
        <ModalOverlay />
        <ModalContent width='96vw' height='94vh'>
          <ModalHeader color='#1D1D20'>Send funds</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button style={consumerPrimaryButtonStyleFull}>Next</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Drawer
        size='lg'
        isOpen={isDrawerOpen}
        placement='bottom'
        onClose={onDrawerClose}
      >
        <DrawerOverlay zIndex={1401} />
        <DrawerContent borderTopRadius={'xl'}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Select new currency
          </DrawerHeader>

          <DrawerBody>
            <Box paddingBottom='1rem'>
              <CoinListViewScreen
                onCoinClick={(symbol) => {
                  setSelectedCoin(symbol)
                  onDrawerClose()
                }}
              ></CoinListViewScreen>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}
