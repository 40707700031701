import { Box, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import { MerchantPayment } from '@prisma/client'
import { currencyCodeToSymbolMap } from '../../../utils/currencyUtils'
import { formatDateForDisplay } from '../../../utils/utils'

export const UserPaymentInfoListItem = ({payment}: {payment: MerchantPayment}) => {
  return (
    <Grid paddingY='.6rem' borderBottom='1px solid #ddd' templateColumns={'repeat(12, 1fr)'}>
      <GridItem colSpan={9}>
        <Stack spacing={0}>
          <Box>
            <Text fontWeight={600} fontStyle='italic'>{payment.message}</Text>
          </Box>
          <Box>
            <Text>{formatDateForDisplay(payment.createdAt)}</Text>
          </Box>
        </Stack>
      </GridItem>
      <GridItem colSpan={3}>
        <Text fontSize='1.1rem' textAlign={'end'}>
          -{currencyCodeToSymbolMap[payment.referenceCurrency]}
          {payment.referenceValue.toFixed(2)}
        </Text>
      </GridItem>
    </Grid>
  )
}
