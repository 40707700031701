import { Box, Text, Stack } from '@chakra-ui/react'
import { usePayleafUser } from '../consumerHooks'
import { Title } from '../labels/Title'
import { UserPaymentInfoListItem } from '../components/PaymentInfoListItem'

export default function RecentTransactionScreen({
  children,
}: {
  children?: React.ReactNode
}) {
  const payleafUserRes = usePayleafUser()


  return (
    <>
      {payleafUserRes.payLeafUser && (
          <Box height={'600px'}>
            <Title>Recent transactions</Title>
            <Stack mt='1rem' spacing='1rem'>
              {payleafUserRes.payLeafUser?.payments.length === 0 && (
                <Text>No payments yet.</Text>
              )}
              {payleafUserRes.payLeafUser?.payments.map((payment) => {
                return (
                  <UserPaymentInfoListItem
                    key={payment.id}
                    payment={payment}
                  ></UserPaymentInfoListItem>
                )
              })}
            </Stack>
          </Box>
        )}
    </>
  )
}