import { useMemo } from 'react'
import { trpc } from '../../../utils/trpc'
import { useUser } from '../../common/auth/authHooks'

export const useBalance = () => {
  const { isAuthed, solanaAuth } = useUser()
  const { data, isLoading, isIdle } = trpc.useQuery(['user.walletBalance'], {
    enabled: isAuthed,
  })

  const total = useMemo(() => {
    if (data) {
      return (
        (data.sol.usd ?? 0) +
        (data.puff.usd ?? 0) +
        (data.usdt.usd ?? 0) +
        data.usdc.usd
      )
    }
    return 0
  }, [data])

  return { total, data, isLoading: isLoading || isIdle }
}
