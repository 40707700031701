import { Box, Button, TabProps, useMultiStyleConfig, useTab } from '@chakra-ui/react'
import React from 'react'

export const CustomPayLeafTab = React.forwardRef<HTMLButtonElement, TabProps>((props, ref) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref: ref as any })
  const isSelected = !!tabProps['aria-selected']

  // 2. Hook into the Tabs `size`, `variant`, props
  const styles = useMultiStyleConfig('Tabs', tabProps)

  return (
    <Button
      __css={styles.tab}
      border='none'
      fontSize='.9rem'
      rounded='full'
      fontWeight='500'
      bg={isSelected ? '#fff' : 'transparent'}
      color={isSelected ? '#333' : '#999999'}
      {...tabProps}
    >
      {tabProps.children}
    </Button>
  )
})

export default CustomPayLeafTab
