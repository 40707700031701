import { Skeleton, Stack } from '@chakra-ui/react'

export const ValuesSkeleton = () => {
  return (
    <Stack spacing='4px' alignItems={'flex-end'}>
      <Skeleton width='4rem' height='1rem' borderRadius='10px' />
      <Skeleton width='3rem' height='1rem' borderRadius='10px' />
    </Stack>
  )
}

export default ValuesSkeleton