import { ECurrency } from '@prisma/client';

export const currencyCodeToSymbolMap = {
  [ECurrency.USD]: '$',
  [ECurrency.EUR]: '€',
  [ECurrency.GBP]: '£',
  [ECurrency.JPY]: '¥',
  [ECurrency.AUD]: 'A$',
  [ECurrency.CAD]: 'C$',
  [ECurrency.CHF]: 'CHF',
}