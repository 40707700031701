import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { labelStyle, subLabelStyle } from '../commonStyles'
import ValuesSkeleton from './valuesSkeleton'

export default function CoinViewListItem({
  isLoading,
  icon,
  description,
  symbol,
  name,
  coinValue,
  onClick,
}: {
  isLoading: boolean
  description?: React.ReactNode
  icon: string
  name: string
  symbol: string
  coinValue?: { value?: number; usd?: number }
  onClick?: () => void
}) {
  return (
    <Flex justifyContent={'space-between'} alignItems='center' onClick={() => onClick?.()}>
      <Flex alignItems={'center'}>
        <Box
          minWidth={'2rem'}
          height='2rem'
          borderRadius={'50%'}
          backgroundSize='100%'
          backgroundImage={icon}
        />
        <VStack spacing='.1rem' ml={'.875rem'}>
          <Text
            w='100%'
            fontSize={'.875rem'}
            fontWeight={600}
            color='#000'
            textAlign={'left'}
          >
            {name}
          </Text>
          {description && (
            <Text fontSize={'.675rem'} color='#999'>
              {description}
            </Text>
          )}
        </VStack>
      </Flex>
      <Box textAlign={'end'} minWidth='7rem'>
        {isLoading ? (
          <ValuesSkeleton />
        ) : (
          <>
            <Text style={labelStyle}>
              ${coinValue?.usd?.toFixed(2) ?? '---'}
            </Text>
            <Text style={subLabelStyle}>
              {coinValue?.value?.toFixed(2) ?? '---'} {symbol}
            </Text>
          </>
        )}
      </Box>
    </Flex>
  )
}
