import {
  Box,
  Flex,
  Link,
  Skeleton,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { trpc } from '../../../utils/trpc'
import { useUser } from '../../common/auth/authHooks'
import { titleStyle } from '../../common/commonStyles'
import CoinViewListItem from '../../common/components/coinListItem'
import { Title } from '../labels/Title'
import { useBalance } from '../hooks/useBalance'

export default function CoinListViewScreen({
  children,
  onCoinClick,
}: {
  children?: React.ReactNode
  onCoinClick?: (symbol: string) => void
}) {
  const { total, data, isLoading } = useBalance()

  return (
    <Stack
      mt='1rem'
      spacing={'.5rem'}
      divider={<StackDivider color='#F2F2F4' />}
    >
      <CoinViewListItem
        onClick={() => onCoinClick?.('USDC')}
        name='US Dollar Coin'
        symbol='USDC'
        description={
          <>
            USDC by Circle is a digital dollar that’s trusted worldwide for
            payments.{' '}
            <Link
              textDecoration={'underline'}
              target='_blank'
              href='https://www.circle.com/en/usdc/businesses'
            >
              Learn more
            </Link>
          </>
        }
        icon='/icons/usdc-logo.svg'
        coinValue={data?.usdc}
        isLoading={isLoading}
      ></CoinViewListItem>
      <CoinViewListItem
        onClick={() => onCoinClick?.('USDT')}
        name='Tether USD'
        symbol='USDT'
        description={
          <>
            USDT is one of the most widely adopted stablecoins and is pegged by
            Tether's reserves.{' '}
            <Link
              textDecoration={'underline'}
              target='_blank'
              href='https://tether.to/en/why-tether'
            >
              Learn more
            </Link>
          </>
        }
        icon='/icons/usdt-logo.svg'
        coinValue={data?.usdt}
        isLoading={isLoading}
      ></CoinViewListItem>
      <CoinViewListItem
        onClick={() => onCoinClick?.('SOL')}
        name='Solana'
        symbol='SOL'
        icon='/icons/logo-solana.svg'
        coinValue={data?.sol}
        isLoading={isLoading}
      ></CoinViewListItem>
      <CoinViewListItem
        onClick={() => onCoinClick?.('PUFF')}
        name='PUFF'
        symbol='PUFF'
        icon='/images/puff-logo.png'
        coinValue={data?.puff}
        isLoading={isLoading}
      ></CoinViewListItem>
    </Stack>
  )
}
