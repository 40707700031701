export const whiteButtonStyle: React.CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #eeeeee',
  borderRadius: '5px',
  color: 'black',
  fontSize: '12px',
  fontWeight: 600,
  height: '32px',
  padding: '0 10px',
  minWidth: '120px'
}

export const selectedWhiteButtonStyle: React.CSSProperties = {
  backgroundColor: 'white',
  border: '2px solid #000000',
  borderRadius: '5px',
  color: 'black',
  fontSize: '12px',
  fontWeight: 600,
  height: '32px',
}

export const cardStyle: React.CSSProperties = {
  background: '#fff',
  border: '1px solid #eee',
  borderRadius: '10px',
  padding: '2rem',
}

export const titleStyle: React.CSSProperties = {
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '120%',
  color: '#000',
}

export const labelStyle: React.CSSProperties = {
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '120%',
  color: '#000',
}

export const subLabelStyle: React.CSSProperties = {
  fontSize: '.675rem',
  fontWeight: 500,
  lineHeight: '120%',
  color: '#676767',
}

export const normalButtonStyle: React.CSSProperties = {
  backgroundColor: '#F2F2F4',
  borderRadius: '5px',
  fontSize: '.875rem',
  fontWeight: 600,
  height: '2rem',
  border: 'none',
}

export const textStyle: React.CSSProperties = {
  fontSize: '.875rem',
  fontWeight: 500,
  lineHeight: '120%',
  color: '#000',
}

export const blackButtonStyle: React.CSSProperties = {
  backgroundColor: '#181430',
  borderRadius: '5px',
  fontSize: '.875rem',
  fontWeight: 600,
  height: '2.5rem',
  border: 'none',
  color: '#fff',
}

export const greenButtonStyle: React.CSSProperties = {
  backgroundColor: '#06792D',
  borderRadius: '5px',
  fontSize: '.875rem',
  fontWeight: 600,
  height: '2.5rem',
  border: 'none',
  color: '#fff',
}
