import { Box, BoxProps, Flex, FlexProps, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { labelStyle, subLabelStyle } from '../commonStyles'
import ValuesSkeleton from './valuesSkeleton'

export default function ButtonListItem({
  icon,
  name,
  description,
  ...props
}: {
  description: React.ReactNode
  icon: React.ReactElement
  name: string
} & FlexProps) {
  return (
    <Flex paddingX='.2rem' alignItems={'flex-start'} {...props}>
      <Flex alignItems={'flex-start'} minWidth={'1.7rem'} height='1.7rem'>
        {icon}
      </Flex>
      <VStack spacing='.1rem' ml={'.875rem'}>
        <Text
          w='100%'
          fontSize={'.875rem'}
          fontWeight={600}
          color='#000'
          textAlign={'left'}
        >
          {name}
        </Text>
        {description && (
          <Text fontSize={'.675rem'} color='#999'>
            {description}
          </Text>
        )}
      </VStack>
    </Flex>
  )
}
